<template>
  <div class="holder text-center mb-2 mb-md-0" @click.self="isHover = !isHover">
    <div class="gold-text medium holder_font" @click.self="isHover = !isHover">{{placeholder}}</div>
    <div class="dropdown_icon gold-text" @click="isHover = !isHover">
      <ios-arrow-down-icon />
    </div>
    <!--DESKTOP-->
    <transition name="slideBottom">
      <div class="select_dropdown" v-if="isHover" @click.self="isHover = !isHover">
        <div class="dropdown_inner">
          <div class="row mx-0 my-4 justify-content-center d-md-none">
            <div class="col-11 text-center title">
              {{title}}
            </div>
            <div class="close cursor" @click="isHover = false"><ios-close-icon class="inline-icon"/></div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner">
            <div class="col-10 py-2" v-for="(option, key) in options" :key="key" >
              <div class="form-check medium">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="radio" :value="option[0]" :id="key" v-model="theSelected">
                  </div>
                  <div class="col-auto p-0">
                    <label class="form-check-label" :for="key">
                      {{option[1].name}}
                    </label>
                  </div>
                </div>
              </div>
              <div class="mt-3" v-if="theSelected === 1 && option[0] === 1">
                <VueDatePicker v-model="date" inline auto-apply model-type="yyyy-MM-dd" :enable-time-picker="false" :month-change-on-scroll="false"></VueDatePicker>
              </div>
              <div class="" v-else-if="theSelected === 2 && option[0] === 2">
                <VueDatePicker v-model="selectedDate" inline auto-apply model-type="yyyy-MM-dd" range :enable-time-picker="false" :month-change-on-scroll="false"></VueDatePicker>
              </div>
            </div>
          </div>
          <div class="row mx-0 my-3 justify-content-center">
            <div class="col-10">
              <Button color="green" width="100" btnText="Apply Filter" icon="arrow" @buttonClicked="applyFilter" :disabled="!dateRange.startDate || !dateRange.endDate">
                <ios-checkmark-icon class="inline-icon" />
              </Button>
            </div>
            <div class="col-10 mt-2" v-if="isFiltered">
              <Button color="red" width="100" btnText="Clear Filter" icon="arrow" @buttonClicked="clearFilter">
                <ios-remove-icon class="inline-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--END DESKTOP-->
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/datepicker.css'
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('./Button.vue')),
    VueDatePicker,
    'ios-remove-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-remove.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'FilterSelectDate',
  props: ['placeholder', 'options', 'title'],
  emits: ['update:modelValue', 'applyfilter'],
  data () {
    return {
      date: null,
      isHover: false,
      isFiltered: false,
      theSelected: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedDate: {
        start: 'First Date',
        end: 'Last Date'
      },
      isRangeStart: false,
      isRangeEnd: false
    }
  },
  watch: {
    date () {
      this.setDate()
    },
    selectedDate: {
      deep: true,
      handler (val) {
        this.setDateRange(this.selectedDate)
      }
    },
    theSelected () {
      this.setDate()
      this.setDateRange()
      this.isRangeEnd = false
      this.isRangeStart = false
    }
  },
  methods: {
    setDate () {
      if (this.theSelected === 1) {
        this.dateRange.startDate = DateTime.fromISO(this.date).startOf('day').ts
        this.dateRange.endDate = DateTime.fromISO(this.date).endOf('day').ts
      } else {
        this.dateRange.startDate = null
        this.dateRange.endDate = null
      }
    },
    setDateRange () {
      if (this.theSelected === 2) {
        if (this.selectedDate.start !== 'First Date') {
          this.dateRange.startDate = DateTime.fromISO(this.selectedDate.start).startOf('day').ts
        }
        if (this.selectedDate.end !== 'Last Date') {
          this.dateRange.endDate = DateTime.fromISO(this.selectedDate.end).endOf('day').ts
        }
      } else {
        this.dateRange.startDate = null
        this.dateRange.endDate = null
      }
    },
    showPicker1 () {
      this.isRangeStart = !this.isRangeStart
      this.isRangeEnd = false
    },
    showPicker2 () {
      this.isRangeEnd = !this.isRangeEnd
      this.isRangeStart = false
    },
    applyFilter () {
      this.$emit('applyfilter', this.dateRange)
      this.isFiltered = true
      this.isHover = false
      this.isRangeEnd = false
      this.isRangeStart = false
    },
    clearFilter () {
      this.$emit('clearfilter')
      this.dateRange = {
        startDate: null,
        endDate: null
      }
      this.date = null
      this.selectedDate = {
        startDate: 'First Date',
        endDate: 'Last Date'
      }
      this.theSelected = null
      this.isFiltered = false
      this.isHover = false
      this.isRangeEnd = false
      this.isRangeStart = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .holder {
    background-color: var(--green-color-dark);
    padding: 12px 24px;
    line-height: 1;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
  }
  .holder_font {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 13px;
    right: 16px;
    font-size: 1rem;
    z-index: 1;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .title {
    color: var(--green-color-dark);
    font-family: "quicksand_medium";
    font-weight: 600;
    font-size: 19px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
      /* background-color: pink; */
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      width: 175%;
      min-width: 250px;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }

    .holder_font {
      font-size: 15px;
    }
    .holder {
      background-color: var(--green-color-dark);
      height: 40px;
      padding: 12px 48px 12px 32px;
      line-height: 1;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
